<template>

  <section class="invoice-preview-wrapper">
    <b-row
      v-if="order"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
  
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h2 class="text-primary invoice-logo">
                    GRUPO LOGISTICO PETRO SAS 
                  </h2>
                </div>
                <h5 class="mb-2">
                  ORDEN DE ENTREGA DE SIMs
                </h5>
                <p class="card-text mb-25">
                  CRA 106 #15 A 25 LT 31 MZ 5BG 18 Y 19 
                </p>
                <p class="card-text mb-25">
                  ZONA FRANCA BOGOTA / BODEGA DELOING
                </p>
                <p class="card-text mb-25">
                  Bogotá D.C., Colombia
                </p>
                <!-- <p class="card-text mb-0">
                  +57 31526233556
                </p> -->
              </div>
  
              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Pedido
                  <span class="invoice-number"> #{{ order.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Estado:
                  </p>
                  <p class="invoice-date">
                    <b-badge :variant="statusVariant(order.order_status.name)">
                      {{ order.order_status.name }}
                    </b-badge>
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Fecha:
                  </p>
                  <p class="invoice-date">
                    {{ formatDateVariant(order.created_at) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Actualización:
                  </p>
                  <p class="invoice-date">
                    {{ formatDateVariant(order.updated_at) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
  
          <!-- Spacer -->
          <hr class="invoice-spacing">
  
          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
  
              <!-- Col: Invoice To -->
              <b-col
                v-if="order.destination"
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Cliente:
                </h6>
                <h6 class="mb-25">
                  {{ order.destination.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ order.destination.contact_name }}
                </p>
                <p class="card-text mb-25">
                  {{ order.destination.address }}, {{ order.destination.city }}
                </p>
                <p class="card-text mb-25">
                  Celular: {{ order.destination.contact_phone }}
                </p>
              </b-col>
  
              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <!-- <div>
                  <h6 class="mb-2">
                    Información adicional:
                  </h6>
                  <span class="ml-75">{{ order.additional_info }}</span>
                </div> -->
              </b-col>
            </b-row>
          </b-card-body>
  
          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="[1]"
            :fields="['referencia', 'unidades', 'descripcion']"
          >
            <template #cell(referencia)="data">
              <b-card-text class="font-weight-bold mb-25">
                SIMs
              </b-card-text>
            </template>
  
            <template #cell(unidades)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ order.amount }}
              </b-card-text>
            </template>
  
            <template #cell(descripcion)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ order.description }}
              </b-card-text>
            </template>
            
          </b-table-lite>
  
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
  
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold text-primary">Requerimientos especiales:</span>
                  <span class="ml-75">{{ order.description }}</span>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
  
          <!-- Spacer -->
          <hr class="invoice-spacing">
  
          <!-- Note -->
          <b-card-body v-if="order.deliverer" class="invoice-padding pt-0">
            <span class="font-weight-bold text-primary">Conductor: </span> <br>
            <br>
            <span> Placas: {{ order.deliverer.plate }}</span> <br>
            <span> Conductor: {{ order.deliverer.name }}</span> <br>
            <span> CC: {{ order.deliverer.document_number }}</span> <br>
          </b-card-body>
        </b-card>
      </b-col>
  
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
  
          <b-spinner
            v-show="loading"
            variant="primary"
            label="Loading..."
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 text-center"
          />
          <!-- Button: Send Invoice -->
          <b-button
            v-show="!loading"
            variant="primary"
            class="mb-75"
            block
            v-b-modal.modal-change-order-status
            v-if="[5,'5'].includes(order.order_status_id)"
          >
            Generar Remisión
          </b-button>
  
          <!-- Button: Download -->
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="downloadOrder"
          >
            Descargar Orden
          </b-button>


          <b-button
            variant="success"
            class="mb-75"
            block
            :to="{ name: 'order-sims', params: { order_id: simOrderId } }"
            v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
          >
            Ver SIMs
          </b-button>

          <b-button
            v-show="!loading"
            variant="secondary"
            class="mb-75"
            block
            @click="simsByOrderExport"
            v-if="[2,3,5,'2','3','5'].includes(order.order_status_id)"
          >
            Descargar SIMs (.xlsx)
          </b-button>
          
  
        </b-card>
      </b-col>
    </b-row>
  
    <b-modal
      id="modal-change-order-status"
      title="Generar remisión"
      ok-title="Generar"
      cancel-title="Cancelar"
      @ok="changeOrderStatus()"
    >
      <b-card-text>
        <h5>Importante</h5>
        <p>¿Estás seguro de generar la remisión de este pedido? Recuerda que esta acción no se puede revertir</p>
      </b-card-text>
    </b-modal>
  
  </section>
  
  </template>
  
  <script>
  import {
    BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardBody, BCardHeader, BCardTitle, BCardText, BFormCheckbox, BTableLite, BBadge, BAlert, BLink, BSpinner
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import { required, } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Logo from '@/@core/layouts/components/Logo.vue'
  import {constants} from "@/helpers/constants"
  import moment from 'moment';
  import {formatDate} from "@/helpers/utils"
  
  export default {
    components: {
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
      BCardText,
      BFormCheckbox,
      BTableLite,
      BBadge,
      BAlert,
      BLink,
      BSpinner,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      Logo,
    },
    data() {
      return {
        host: constants.hostUrl,
        accessToken: localStorage.getItem('accessToken'),
        userData: JSON.parse(localStorage.getItem('userData')),
        order: null,
        products: null,
        loading: false,
        required,
        invoiceData: null,
        status: [{
          1: 'En alistamiento',
          2: 'Despachado',
          3: 'Cancelado',
          4: 'Finalizado',
          5: 'Recibido',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
      }
    },
    props: {
      simOrderId: {
        type: Number,
        required: true,
      },
    },
    created() {
      this.getOrder()
    },
    computed: {
      statusVariant() {
        const statusColor = {
          /* eslint-disable key-spacing */
          'En alistamiento'      : 'light-primary',
          Despachado : 'light-success',
          Cancelado     : 'light-danger',
          Finalizado     : 'light-warning',
          Recibido      : 'light-info',
          /* eslint-enable key-spacing */
        }
  
        return status => statusColor[status]
      },
      formatDateVariant() {
        return date => formatDate(date)
      },
    },
    methods: {
      changeOrderStatus() {
        this.loading = true
  
        let headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken
        }
        axios.post(this.host + '/api/front-web/dispatch-sim-order', {
          order_id: this.simOrderId,
        }, { headers }).then(response => {
          console.log('dispatch-sim-order: ', response)
          this.loading = false
  
          if(response.data.status) { 
            this.order.order_status_id = response.data.data.order.order_status_id
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `OK`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: `Advertencia`,
                icon: 'XCircleIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
  
        
        }).catch( err => {
          console.log(err)
        }) 
      },
      simsByOrderExport() {
  
        this.loading = true
        const vm = this
  
        let xhr = new XMLHttpRequest();
        xhr.open('post', this.host + '/api/front-web/sims-by-order-export', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
        xhr.responseType = 'arraybuffer';
  
        xhr.onload = function () {
          vm.loading = false
          if (this.status === 200) {
            let date = moment().format('DD-MM-YYYY HH:mm:ss');
  
            let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'sims-pedido-' + vm.simOrderId + ' - ' + date + '.xlsx';
            link.click();
            
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'No se ha descargado el archivo',
                icon: 'SlashIcon',
                variant: 'warning',
              },
            }) 
          }
        };
        let params = 'order_id=' + this.simOrderId;
        xhr.send(params)
        xhr.th
  
      },
      
      getOrder() {
        let headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.accessToken
        }
        axios.get(this.host + '/api/front-web/get-sim-order-detail/' + this.simOrderId, { headers }).then(response => {
          console.log('get-sim-order-detail: ', response)
  
          this.order = response.data
          
        }).catch( err => {
          console.log(err)
        })
      },
      downloadOrder() {
        window.print()    
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
  
      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
  
  