<template>

  <b-card>

    <b-row>
      <b-col cols="12">

        <sim-order-detail-card 
          :sim-order-id="simOrderId"
          class="mt-2 pt-75"
        />

      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import SimOrderDetailCard from './SimOrderDetailCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

    SimOrderDetailCard
  },
  data() {
    return {
      simOrderId: null,
    }
  },
  created() {
    this.simOrderId = router.currentRoute.params.sim_order_id
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>